import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyA8o7Joo2CadK1cGz4GqPUHifX9UuYZqvs",
  authDomain: "skwilti.firebaseapp.com",
  projectId: "skwilti",
  storageBucket: "skwilti.appspot.com",
  messagingSenderId: "395146428919",
  appId: "1:395146428919:web:0890b8f35aff1d385faef2",
  measurementId: "G-TCYDGGL2GL"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
export default db;