import React, { useState } from 'react'
import "./Login.css";
import emailjs from 'emailjs-com'
import  { Link } from 'react-router-dom'
import db from '../firebase1'
import { useEffect } from 'react';
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore"; 




function Login() {
    const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [user, setUsers] = useState([]);
    useEffect(() => {
      
        

      
    }, [])
    
	const changeHandler = (type, e) => {
		if (type === "email") {
			setEmail(e.target.value);
		}
		if (type === "password") {
			setPassword(e.target.value);
		}
	};
    
    const register = (e) => {
		e.preventDefault();
		if (email && password) {
			
		}
		setEmail("");
		setPassword("");
	};

    const signIn = (e) => {
		e.preventDefault();
     if(email){
         setDoc(doc(db,"users","a | "+email), { email , timestamp : serverTimestamp() });
         window.location.href = "https://drive.google.com/file/d/1_W3oMQhbjdDYWCM5TTK7XcQy2gbMZWDh/view?usp=sharing";
     }

        setEmail("");
		
	};

    
  return (
    <div className='login'>
        <div className="login__container">
				<img src="./without_background.png" />
                <h3 className='login__title'>Le siteweb <em style={{color:"red"}}>skwilti.ma</em> est en train de construction</h3>

                        <p style={{fontSize: "20px", fontWeight: "600" }}>Pour reçevoir le résumer du programme de 
                        la troisième année collège veuillez saisir votre email.</p>
                <form onSubmit={signIn} id="form" className="login__form">
                    <div className='login__form__content'>

                        <div className="login__field">
                            <label>Email : </label>
                            <input
                                type="email"
                                value={email}
                                onChange={changeHandler.bind(this, "email")}
                            />
                        </div>
                    

                        <button className="login__button" >
                            Obtenir le Résumé
                        </button>
                    </div>

					
					
    
				</form>
                <img className='pdf_image' src="./10.png" width="200px" />

			</div>
    </div>
  )
}

export default Login