import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Main from './pages/Main';
import Login from './pages/Login';

function App() {
  return (
			<div className="app">
      <Login/>
			</div>
  );
}

export default App;
